import type { Cycle } from '@tn/shared';
import { defineStore } from 'pinia';

const REPORT_OPEN_DATE = 20;

export const useCyclesStore = defineStore('cycles', () => {
  const now = useNow();
  const cycles = ref<Cycle[]>([]);
  const isLoading = ref(false);
  const { $sentry } = useNuxtApp();

  const fetchCycles = async () => {
    if (cycles.value.length > 0) return;

    try {
      isLoading.value = true;
      const { data } = await useFetch('/api/cycles');

      if (data.value) {
        cycles.value = data.value;
      }
    } catch (error) {
      $sentry.captureException(error);
      console.error(error);
    } finally {
      isLoading.value = false;
    }
  };

  const getCycleFromMsTimestamp = (msTimestamp: number) => {
    return (
      cycles.value.find(
        (cycle) =>
          cycle.startDate <= msTimestamp && cycle.endDate >= msTimestamp
      ) || null
    );
  };

  const getCycleByCycleNumber = (cycleNumber: number) => {
    return cycles.value?.find((cycle) => cycle.cycleNumber === cycleNumber);
  };

  const currentCycle = computed(() => getCycleFromMsTimestamp(now.value));
  const nextCycle = computed(() =>
    currentCycle.value
      ? getCycleByCycleNumber(currentCycle.value.cycleNumber + 1)
      : null
  );
  const previousCycle = computed(() =>
    currentCycle.value
      ? getCycleByCycleNumber(currentCycle.value.cycleNumber - 1)
      : null
  );

  const isReviewPeriodOpen = computed(() => {
    return (
      !!currentCycle.value &&
      now.value >= currentCycle.value.reviewStartDate &&
      now.value < currentCycle.value.endDate
    );
  });

  const isReportPeriodOpened = computed(() => {
    const date = new Date(now.value);
    const nowUTC = new Date(
      Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds(),
        date.getUTCMilliseconds()
      )
    );

    const year = date.getFullYear();
    const month = date.getMonth();
    const reportOpenDate = new Date(
      Date.UTC(year, month, REPORT_OPEN_DATE, 0, 0, 0)
    );

    return nowUTC >= reportOpenDate;
  });

  return {
    cycles,
    fetchCycles,
    currentCycle,
    nextCycle,
    previousCycle,
    isLoading,
    isReviewPeriodOpen,
    getCycleFromMsTimestamp,
    getCycleByCycleNumber,
    isReportPeriodOpened,
  };
});
