import { useQueryClient } from '@tanstack/vue-query';
import { fetchCurrentUser } from '~/features/auth';

export default defineNuxtPlugin({
  name: 'prefetch-data',
  dependsOn: ['vue-query'],
  async setup() {
    const queryClient = useQueryClient();

    // Prefetch queries
    await queryClient.prefetchQuery({
      queryKey: ['me'],
      queryFn: () => fetchCurrentUser(),
    });
  },
});
